import {
	CREATE_CART, ADD_CART_PRODUCTS, ADD_CART_COUPON, REMOVE_CART_COUPON, REMOVE_CART, GET_CART,
	GET_CART_SHIPPING_METHOD, GET_CART_PAYMENT_METHOD, GET_CART_INFO,
	GET_CARTS_ESTIMATED_TOTALS, USE_POINTS, REMOVE_POINTS, SPLIT_PAYMENT,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class Cart {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
	}

	clear() {
		this.data.clear();
	}

	getCart(cartId, options, section) {
		let isRegister = 0;
		let payload = {
			section,
		};
		if (options && options.is_register) isRegister = options.is_register;
		payload = { ...payload, ...(options ?? {}) };
		const { method, endpoint } = GET_CART;
		return this.data[method](endpoint(cartId), payload, true, isRegister).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createCart() {
		const { method, endpoint } = CREATE_CART;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	addCartProducts(cartId, payload) {
		// refactor:
		// 	if the var name 'product' is necessary in payload
		// 	we need add it
		const { method, endpoint } = ADD_CART_PRODUCTS;
		return this.data[method](endpoint(cartId), payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	removeCart(cartId) {
		const { method, endpoint } = REMOVE_CART;
		return this.data[method](endpoint(cartId)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	setCartCoupon(cartId, payload) {
		const { method, endpoint } = ADD_CART_COUPON;
		return this.data[method](endpoint(cartId), payload);
	}

	removeCartCoupon(cartId, payload) {
		const { method, endpoint } = REMOVE_CART_COUPON;
		return this.data[method](endpoint(cartId), payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors = error;
			return Promise.reject(error);
		});
	}

	getShippingMethod(cartId, options, settings) {
		const { method, endpoint } = GET_CART_SHIPPING_METHOD;
		return this.data[method](endpoint(cartId), options, true, settings).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPaymentMethods(options) {
		const { method, endpoint } = GET_CART_PAYMENT_METHOD;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCartInfo(cartId) {
		const { method, endpoint } = GET_CART_INFO;
		return this.data[method](endpoint(cartId)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCartTotalsEstimates(payload) {
		const { method, endpoint } = GET_CARTS_ESTIMATED_TOTALS;
		return this.data[method](endpoint, payload);
	}

	usePoints(cartId) {
		const { method, endpoint } = USE_POINTS;
		return this.data[method](endpoint(cartId)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	removePoints(cartId) {
		const { method, endpoint } = REMOVE_POINTS;
		return this.data[method](endpoint(cartId)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	splitPayment(cartId, payload) {
		const { method, endpoint } = SPLIT_PAYMENT;
		return this.data[method](endpoint(cartId), payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Cart;
